import { links } from '../components/submission/common';
import { inSellerDashboardExperiment } from './experiment';
import { CookieStore } from '../services/cookieStore';

const { REACT_APP_SITE_URL, REACT_APP_SITE_URL_DEV } = process.env;

export const redirectTo = (url: string) => {
  const uvid = CookieStore.getUvid();
  let newUrl = url;
  if (uvid) {
    const urlObj = new URL(url); // eslint-disable-line
    urlObj.searchParams.append('uvid', uvid);
    newUrl = urlObj.toString();
  }
  window.location.replace(newUrl);
};

export const directToItemTypePage = () => {
  window.location.href = links.submissionFlowRepositioning;
};

export const directToHomePage = () =>
  redirectTo(REACT_APP_SITE_URL_DEV || REACT_APP_SITE_URL || '');

export const redirectToNewItem = (itemId: string) => {
  const sellerDashboardExperiment = inSellerDashboardExperiment();
  const newItemUrl = sellerDashboardExperiment
    ? `${process.env.REACT_APP_SELLER_DASHBOARD_URL}/shipping?itemId=${itemId}&survey=true` || ''
    : `${process.env.REACT_APP_APP_URL}/item/${itemId}/skip_photo_upload`;
  redirectTo(newItemUrl);
};
